import styled from 'styled-components'

export const Wrapper = styled.div.attrs({
  className: 'animated-intro',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  & > div {
    height: 100%;

    & .intro-container {
      height: 100%;

      & > div {
        height: 100% !important;
      }
    }
  }

  & .bp1-fundo,
  & .bp2-fundo {
    height: 100% !important;
    object-fit: cover;
  }

  & .button1,
  & .button2,
  & .bp1-button,
  & .bp2-button1,
  & .bp2-button2 {
    cursor: pointer !important;
  }

  .bp1-fundo-layout-container,
  .bp2-fundo-layout-container,
  .background-image,
  .bp1-abackground,
  .bp2-abackground {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
  }
`
